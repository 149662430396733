<template>
  <div class="trial-box pr" v-cloak>
    <div class="trial-bg pa" />
    <div class="trial-elem">
      <div class="trial-service u-flex u-col-center">
        <i class="trial-icon-service"></i>
      </div>

      <div class="trial-item">
        <!-- 初审成功图片 -->
        <div class="u-flex u-row-center u-col-center">
          <img
            class="trial-center-img"
            src="../assets/images/trial_center.png"
          />
        </div>

        <!-- 审核流程 -->
        <div class="trial-step">
          <!-- 初审 -->
          <div class="trial-step-item u-flex u-row-between u-col-top">
            <div class="trial-step-icon">
              <img src="../assets/images/trial_success.png" />
            </div>
            <div class="u-flex-1">
              <div class="trial-step-tit">恭喜您！初审通过</div>
              <div class="trial-gray">{{ `预估额度：${loanAmount}元` }}</div>
            </div>
          </div>
          <!-- 终审 -->
          <div class="trial-step-item u-flex u-row-between u-col-top">
            <div class="trial-step-icon">
              <img src="../assets/images/trial_mobile.png" />
            </div>
            <div class="u-flex-1">
              <div class="trial-step-tit trial-blue">接听电话，通过终审</div>

              <div class="trial-black">
                此额度需要资方/机构电话确认，将于30分钟内联系您(9:00-18:00)，请您保持电话通畅，
                <span class="trial-yellow">请一定注意接听来电</span>
              </div>
            </div>
          </div>
        </div>

        <div class="organ-elem">
          <div class="organ-top-bg" />
          <div class="origan-item">
            <div class="origan-desc">— 请注意接听该机构电话 —</div>
            <div class="u-flex u-row-between u-col-center">
              <p
                class="u-text-center origan-right"
                v-if="applyId === '0' || !productName"
                style="width: 100%"
              >
                正在匹配中...
              </p>
              <template v-else>
                <div class="origan-left u-flex u-col-center">
                  <img
                    :src="
                      productLogo
                        ? productLogo
                        : '../assets/images/trial_success.png'
                    "
                  />
                  <span class="origan-tit u-flex-1">{{ productName }}</span>
                </div>
                <div class="origan-right u-flex-col">
                  <span>将于30分钟内联系您</span>
                  <span class="u-text-center">(9:00-18:00)</span>
                </div>
              </template>
            </div>
          </div>
        </div>

        <!-- 下载APP，查看实时放款进度 -->
        <van-button
          v-if="isRedirectProductsPage === 0"
          class="trial-btn"
          type="primary"
          round
          @click="handleDownload"
        >
          下载APP，查看实时放款进度
        </van-button>

        <!-- 我知道了 -->
        <van-button
          v-else
          class="trial-btn"
          type="primary"
          round
          @click="handleIGotIt"
        >
          我知道了
        </van-button>
      </div>
    </div>

    <div v-if="productInfoObj15" class="product-elem">
      <h5 class="product-elem-title">
        根据您的信用情况,您还可以快速在以下产品获得贷款
      </h5>

      <!-- 产品展示 -->
      <div class="product-display">
        <div class="u-flex" @click="handleProductClick(productInfoObj15, 15)">
          <img
            class="product-img"
            :src="
              productInfoObj15.imgUrl
                ? productInfoObj15.imgUrl
                : '../assets/images/trial_success.png'
            "
          />
          <div class="product-title u-flex-col u-row-between">
            <div class="u-flex">
              <h4>{{ productInfoObj15.productName }}</h4>
              <van-tag
                v-for="(item, index) in productInfoObj15.tagList"
                :key="index"
                color="rgba(53,99,250,0.1)"
                text-color="#3563FA"
              >
                {{ item }}
              </van-tag>
            </div>
            <p class="credit-for-you">
              为您授信
              <span>
                {{ productInfoObj15.creditAmount }}
              </span>
              元贷款额度
            </p>
          </div>
        </div>
        <ul class="u-flex u-row-between product-display-ul u-text-left">
          <li class="u-flex-col">
            <p>{{ `${productInfoObj15.timeLimitLoanRate}%` }}</p>
            <small>限时综合年化</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.loanPeriod }}</p>
            <small>最高可借期限(月)</small>
          </li>
          <li class="u-flex-col">
            <p>{{ productInfoObj15.maxAmount }}</p>
            <small>最高借款金额 (元)</small>
          </li>
        </ul>
        <p class="originate-loan">
          已成功向
          <span>{{ productInfoObj15.loanNumber }}</span>
          人发起贷款
        </p>
      </div>
    </div>

    <!-- 产品弹窗 -->
    <template v-if="productInfoObj16">
      <van-popup
        v-model="show"
        class="product-layer u-flex-col u-col-center"
        round
        :close-on-click-overlay="false"
      >
        <h4>为您匹配到合适的贷款产品</h4>
        <img :src="productInfoObj16.imgUrl" />
        <h5>{{ productInfoObj16.productName }}</h5>
        <p>
          您最高可借
          <span>{{ productInfoObj16.maxAmount }}</span>
        </p>
        <div
          class="btn-now u-text-center"
          @click="handleProductClick(productInfoObj16, 16)"
        >
          立即申请
        </div>
        <div class="btn-not u-text-center" @click="show = false">先不用</div>
      </van-popup>
    </template>

    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import * as API_Common from "@/api/common.js";

export default {
  name: "TrialSuccessTl1",
  data() {
    return {
      source: this.$route.query.source, // 渠道标识
      loanAmount: this.$route.query.loanAmount, // 金额
      applyId: this.$route.query.applyId, // 进件申请id(0为不成功，无需调用接口查询机构信息,不为0需要查询)
      isRedirectProductsPage: parseInt(
        this.$route.query.isRedirectProductsPage
      ), // 标识是否是贷超流程1是 0否
      productLogo: "", // 机构ICON
      productName: "", // 机构名称
      downAppUrl: undefined, // 下载地址
      showPop: false, // 分享蒙层状态
      show: false, // 产品弹窗
      // showPage: 15,H5进件完成页(用于页面底部的产品详情查询),16,投放页完成下载APP(用于页面弹窗的产品详情查询),
      productInfoObj15: null,
      productInfoObj16: null,
    };
  },
  mounted() {
    let isWeixin = this.isWeixin();
    if (isWeixin) {
      this.showPop = true;
    }

    // 如果进件申请id不为0则查询机构信息
    if (this.applyId !== "0") {
      this.getMechanismInfo();
    }

    this.getDownloadUrl();

    // 获取页面底部的产品详情信息
    this.getProductInfo(15);

    // 获取弹窗内部产品详情信息
    this.getProductInfo(16);

    // 添加浏览器浏览记录(贷超流程增加这个历史记录，非贷超流程不需要增加这个历史记录)
    // if (this.isRedirectProductsPage === 1) {
    //   this.addHistory();
    // }
  },
  methods: {
    // 下载按钮点击
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        if (this.brand === "xiaomi") {
          window.location.href = "https://app.xiaomi.com/detail/1500555";
        } else {
          // 有弹窗内部推荐产品的时候才允许弹窗
          if (this.productInfoObj16) {
            this.show = true;
          }

          setTimeout(() => {
            window.location.href = this.downAppUrl;
          }, 50);
        }
      }
    },

    // 我知道了按钮点击
    handleIGotIt() {
      var timestamp = new Date().getTime();
      window.location.href = `/productList.html?source=${this.source}&tsp=${timestamp}`;
    },

    // 获取机构信息
    getMechanismInfo() {
      API_Common.getMechanismInfo(this.applyId).then((res) => {
        if (res.code === 200) {
          // 进件是否推送成功;1:成功(会返回对应的产品信息)、0:失败(不会返回产品信息)
          if (res.data.hasPushSuccess === 1) {
            this.productName = res.data.productName;
            this.productLogo = res.data.productLogo;
          }
        } else {
          this.$toast(res.msg);
        }
      });
    },

    // 获取下载地址
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel, true).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        } else {
          this.$toast.fail("获取下载地址失败,请重试");
        }
      });
    },

    // 监听提示浏览器打开弹层（我知道了）按钮点击
    handlePopChange(show) {
      this.showPop = show;
    },

    // 获取产品信息
    getProductInfo(showPage) {
      API_Common.getProductInfo({ showPage }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this[`productInfoObj${showPage}`] = res.data;
          }
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    // 监听产品点击
    handleProductClick(obj, showPage) {
      // 埋点
      API_Common.buried({
        productId: obj.productId,
        showPage,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(() => {
            var timestamp = new Date().getTime();
            window.location.href = obj.linkUrl + "?tsp=" + timestamp;
          }, 500);
        }
      });
    },

    // 给浏览器的访问记录添加一条此页面的访问记录
    addHistory() {
      window.history.pushState(null, null, "productList.html");
    },
  },
};
</script>

<style lang="scss" scoped>
.trial-box {
  padding: 0 0.4rem 0.4rem;
  background: #f2f5fa;
  height: 100vh;
  overflow: auto;

  .trial-bg {
    display: block;
    width: 100%;
    height: 7.2rem;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #3563fa, rgba(242, 245, 250, 0) 90%);
    background-size: 100% 7.2rem;
    z-index: 0;
  }

  .trial-elem {
    position: relative;
    z-index: 1;
  }

  .trial-service {
    padding: 0.2rem 0;
    .trial-icon-service {
      display: block;
      width: 0.28rem;
      height: 0.28rem;
      background: url("../assets/images/trial_service.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 0.12rem;
    }

    .trial-phone {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.34rem;
    }
  }

  .trial-item {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.4rem 0.3rem;

    .trial-center-img {
      display: block;
      width: 2rem;
      height: 2.04rem;
      margin-bottom: 0.26rem;
    }
  }

  .trial-step {
    margin-bottom: 0.24rem;

    .trial-step-item {
      padding-bottom: 0.26rem;
      position: relative;

      &::after {
        display: block;
        content: "";
        border-left: 1px dashed #979797;
        width: 0;
        height: 100%;
        position: absolute;
        left: 0.17rem;
        top: 0.06rem;
        z-index: 0;
      }

      &:last-child::after {
        border: none;
      }

      .trial-step-icon {
        width: 0.36rem;
        height: 0.36rem;
        margin-top: 0.06rem;
        margin-right: 0.16rem;
        position: relative;
        z-index: 1;

        > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .trial-step-tit {
        font-size: 0.36rem;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #111c31;
        line-height: 0.5rem;
      }

      .trial-gray {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #868e9e;
        line-height: 0.34rem;
      }

      .trial-black {
        color: #111c31;
        font-size: 0.27rem;
        line-height: 0.4rem;
        text-align: justify;
      }

      .trial-yellow {
        color: #fc6a2f;
      }

      .trial-blue {
        color: #3563fa;
      }
    }
  }

  .organ-elem {
    position: relative;
    padding: 0.14rem 0.2rem;

    .organ-top-bg {
      width: 100%;
      height: 0.28rem;
      background: #3563fa;
      border-radius: 0.04rem;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    .origan-item {
      background: #f6f5fa;
      border-radius: 0.08rem;
      position: relative;
      z-index: 1;
      padding: 0.3rem 0.2rem 0.44rem;

      .origan-desc {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Thin;
        font-weight: 200;
        text-align: center;
        color: #111c31;
        line-height: 0.34rem;
        margin-bottom: 0.4rem;
      }

      .origan-left {
        > img {
          display: block;
          width: 0.52rem;
          height: 0.52rem;
          background: #e3e3e3;
          margin-right: 0.12rem;
          border-radius: 50%;
          overflow: hidden;
        }

        .origan-tit {
          font-size: 0.28rem;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: 500;
          color: #111c31;
          line-height: 0.4rem;
        }
      }

      .origan-right {
        font-size: 0.28rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #111c31;
        line-height: 0.4rem;
      }
    }
  }

  .trial-btn {
    display: block;
    width: 100%;
    background: #3563fa;
    border-color: #3563fa;
    font-size: 0.32rem;
    margin-top: 0.48rem;
  }

  .product-elem {
    margin: 0.2rem 0;

    .product-elem-title {
      font-size: 0.28rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
    }

    .product-display {
      background: #fff;
      border-radius: 0.24rem;
      margin-top: 0.24rem;
      padding: 0.3rem;

      > div {
        padding-bottom: 0.24rem;
        border-bottom: 0.02rem solid #e6e7ee;
      }

      .product-img {
        width: 0.96rem;
        height: 0.96rem;
        border-radius: 0.2rem;
        margin-right: 0.24rem;
      }

      .product-title {
        font-size: 0.32rem;
        font-family: PingFangSC, PingFangSC-Semibold;
        font-weight: bold;
        color: #111c31;

        .van-tag {
          margin-left: 0.1rem;
          padding: 0.02rem 0.1rem;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          margin-left: 0.12rem;
        }
      }

      .credit-for-you {
        margin-top: 0.05rem;
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }

      .product-display-ul {
        margin: 0.24rem 0 0.4rem;

        p {
          font-size: 0.4rem;
          font-family: AlibabaPuHuiTiR, AlibabaPuHuiTiR-Regular;
          font-weight: normal;
          color: #111c31;
        }

        small {
          font-size: 0.22rem;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: normal;
          color: #868e9e;
        }
      }

      .originate-loan {
        font-size: 0.24rem;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: normal;
        color: #868e9e;
        > span {
          color: #f03d23;
        }
      }
    }
  }

  .product-layer {
    width: calc(100% - 1.52rem);
    padding: 0.36rem 0.46rem;
    > h4 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > img {
      margin: 0.48rem 0 0.16rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 0.2rem;
    }

    > h5 {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Semibold;
      font-weight: bold;
      color: #111c31;
    }

    > p {
      margin: 0.16rem 0 0.3rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: normal;
      color: #111c31;
      > span {
        color: #fb5e1e;
      }
    }

    > div {
      width: 100%;
      height: 0.96rem;
      line-height: 0.96rem;
      border-radius: 0.48rem;
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: normal;
    }

    > .btn-now {
      background: #3563fa;
      color: #ffffff;
    }

    > .btn-not {
      color: #868e9e;
    }
  }
}
</style>
